import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import "./applicant-scholarship-page.scss";
import AppPage from '../../../Core/AppPage';
import Footer from '../../../Components/Footer/Footer';
import ScholarshipHeader from '../../../Components/ScholarshipHeader/ScholarshipHeader';
import ScholarshipBody from '../../../Components/ScholarshipBody/ScholarshipBody';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import AppliedApplicationList from '../../../Components/AppliedApplicationList/AppliedApplicationList';
import { ToastContainer } from 'react-toastify';
import Loader from '../../../Components/Loader/Loader';
import { MAIN_CONFIG } from '../../../../config/main';
import Custom500 from '../../../../pages/500'
import { get, isEmpty } from 'lodash';
import { handleRedirectHrefUrl } from '../../../Utility/HelperFunctions';


const ApplicantScholarship = ({ pageData, accountData, userContext, setActiveHeaderNav, setModalState = () => { } }) => {
    const API = new KaleidoscopeAPI({});
    const router = useRouter();
    const { scholarshipId } = router.query;
    const [loading, setLoading] = useState(false);
    const [redirected, setRedirected] = useState(false);
    const [account, setAccount] = useState(get(pageData, 'scholarshipDetail.account', {}));
    const [scholarship, setScholarship] = useState(get(pageData, 'scholarshipDetail.scholarship', {}));
    const [otherscholarship, setOtherScholarship] = useState(get(pageData, 'scholarshipDetail.other_scholarships', {}));
    const [applicationStep, setApplicationStep] = useState(get(pageData, 'scholarshipDetail.application_step', {}));
    const [appliedApplication, setAppliedApplication] = useState(get(pageData, 'scholarshipDetail.applied_applications', []));
    const [isShowBack,setIsShowBack]= useState(sessionStorage?.getItem('showBack')=='true'||false)
    const [filteredApplications, setFilteredApplications] = useState(get(pageData, 'scholarshipDetail.applied_applications', []))

    const shouldExcludeTile = (application) => {
        return !(
          !application.scholarship?.elements_program__c &&
          application.elements_app__c
        );
      };

    useEffect(() => {
        sessionStorage.removeItem('previousPath')
        const applicationsToShow = appliedApplication.filter(shouldExcludeTile);
        setFilteredApplications(applicationsToShow);

        for (var path in router.components) {
            if (path === '/applicant' || path==='/'||path==='/applicant/applications'|| path==='/applicant/[indexId]') {
                setIsShowBack(true)
                sessionStorage.setItem('showBack', true)
            }
        }
        return () => {
            sessionStorage.setItem('showBack', false)
            setIsShowBack(false)
        }
    }, [])
 

    useEffect(() => {
        const redirectToProgram = async () => {
            setLoading(true);
            if(!redirected){
                // redirect on the elements url
                if ( router.asPath.includes('scholarships') && scholarship?.elements_program__c) {
                    handleRedirectHrefUrl(MAIN_CONFIG.ELEMENTS_URLS.APPLICANT.SCHOLARSHIP(scholarship?.url_name__c));
                    setRedirected(true);
                    // await router.push(elementsProgramUrl, undefined, { shallow: true });
                }
                setLoading(false);
                window.scrollTo(0, 0);
            }
        };
        redirectToProgram();
    }, [router.query.scholarshipId, scholarship?.elements_program__c, redirected]);


    /**
     * remove the modal content and hide the modal.
     */
    const hideModal = (e) => {
        setModalState({
            visible: false,
            className: '',
            content: []
        });
    }

    const checkEnableButton = () => {

        let res = (new Date(scholarship.open_date) > new Date() && scholarship.status__c === 'Accepting Applications') ? true : false
        return res
    }
    /**
     * Adds the modal content and shows the modal.
     */
    const showModal = (e) => {
        e.preventDefault();
        localStorage.setItem('secondaryColor', scholarship.secondary_branding_color__c || account.secondary_color__c || MAIN_CONFIG.COLORS.fillGreen)
        setModalState({
            visible: true,
            className: 'applied-application__List',
            content: <AppliedApplicationList
                handleClose={hideModal}
                appliedApplication={appliedApplication}
                scholarshipId={scholarshipId}
                scholarship={scholarship}
                setLoading={setLoading}
                applicationStepId={applicationStep.sfid}
                color={scholarship.primary_branding_color__c || account.primary_color__c}
                secondaryColor={scholarship.secondary_branding_color__c || account.secondary_color__c}
            />
        });
    }
    return (
        <div id={userContext !== "LOGGED_OUT" ? "after-loged-out-page-content" : ""}>
            <Loader loading={loading} />
            <div className="applicant-scholarship">
                {!isEmpty(scholarship) ? (
                    <>
                        <ScholarshipHeader name={scholarship.name}
                            logo={scholarship.scholarshiplogourl__c}
                            status={scholarship.status__c}
                            date={scholarship.deadline_date_time__c}
                            award={scholarship.scholarship_fund_amount__c}
                            color={scholarship.primary_branding_color__c || account.primary_color__c}
                            // handleAction={scholarship.is_scholarship_bookmarked ? handleRemove : handleSaved}
                            scholarship={scholarship}
                            userContext={userContext}
                            scholarshipHeaderLogo={scholarship.scholarship_header_logo_url__c}
                            formType={scholarship.form_type__c}
                            checkEnableButton={checkEnableButton}
                            showBack={isShowBack}
                            setLoading={setLoading}
                        />
                        <ScholarshipBody
                            scholarship={scholarship}
                            account={account}
                            // handleAction={scholarship.is_scholarship_bookmarked ? handleRemove : handleSaved}
                            userContext={userContext}
                            color={scholarship.primary_branding_color__c || account.primary_color__c}
                            applicationStep={applicationStep || {}}
                            setLoading={setLoading}
                            secondaryColor={scholarship.secondary_branding_color__c || account.secondary_color__c || "#008067"}
                            showModal={showModal}
                            appliedApplication={filteredApplications}
                            setModalState={setModalState}
                            checkEnableButton={checkEnableButton}
                            autoApplyScholarship={scholarship.autoapply_scholarship__c}
                        />
                    </>
                )
                    :
                    (<Custom500 />)
                }
                <ToastContainer />
            </div>
            <Footer
                userContext={userContext}
                poweredByKaleidoscope={scholarship ? scholarship.powered_by_kaleidoscope__c : ''}

            />
        </div>
    );
};

const ApplicantScholarshipPage = AppPage(ApplicantScholarship);

export default ApplicantScholarshipPage;
